import { graphql, PageProps } from 'gatsby';
import React from 'react';
import Layout from '../layouts/Layout';
import SEO from '../components/SEO';
import Event from '../components/Event';
import Section from '../components/formatting/Section';

interface Props extends PageProps {
  data: {
    file: App.MDXType<App.Event>;
  };
}

const EventTemplate: React.FC<Props> = function (props) {
  const { data, location } = props;
  const {
    title,
    content,
    date_ranges,
    html_embed_code,
  } = data.file.childMdx.frontmatter;

  return (
    <Layout>
      <SEO title={title} path={location?.pathname} />
      <Event event={data.file.childMdx.frontmatter} />
      <Section>
        {html_embed_code && (
          <div dangerouslySetInnerHTML={{ __html: html_embed_code }} />
        )}
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query($absolutePath: String!) {
    file(absolutePath: { eq: $absolutePath }) {
      childMdx {
        body
        frontmatter {
          link
          title
          content
          pub_date
          rsvp_link
          date_ranges {
            start_date
            end_date
          }
          address
          html_embed_code
        }
      }
    }
  }
`;

export default EventTemplate;
